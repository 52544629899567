<template>
  <div id="join-session">
    <div v-if="badgeEvent">
      <div class="is-flex js-between border-b mg-t-5">
        <p class="font-14 color-35 font-weight-500">
          {{ badgeEvent.courseId }}.{{ badgeEvent.skillType }} ({{
            badgeEvent.activityDescription
          }})
        </p>
        <p class="font-12 color-blue-dark font-weight-400">
          {{ badgeEvent.learningHour }}
          {{
            badgeEvent.learningHour > 1 ? " Learning hours" : " Learning hour"
          }}
        </p>
      </div>

      <div class="mg-t-5 text-center">
        <!-- <pre>{{ badgeEvent }}</pre> -->
        <p class="font-14 color-35 mg-t-7 font-weight-500">
          {{ badgeEvent.topic }}
        </p>
        <p class="font-14 color-35 mg-t-7 font-weight-400">
          {{ badgeEvent.speakerName }}
        </p>
        <p class="font-14 color-35 mg-t-7 font-weight-400">
          {{ badgeEvent.sessionDescription }}
        </p>
        <p class="color-70 font-12 mg-t-7 font-weight-500">
          {{ showDateTH(badgeEvent.sessionDate) }}
          เวลา
          {{ sessionTime(badgeEvent.sessionStartTime) }} น. -
          {{ sessionTime(badgeEvent.sessionEndTime) }} น.
        </p>
      </div>
      <div v-if="showButton">
        <!-- regisCourse: {{ regisCourse }} -->
        <div v-if="regisCourse == 1">
          <el-button
            type="primary"
            class="w-100 mg-t-6"
            round
            @click="updateBooking()"
            >Add to My Courses</el-button
          >
        </div>

        <div v-else-if="regisCourse == 2">
          <el-button
            type="danger"
            class="w-100 mg-t-6"
            round
            @click="updateBooking()"
          >
            <!-- <i class="el-icon-circle-check"></i> สนใจ Course แล้ว -->
            Cancel
          </el-button>
        </div>
        <div v-else>
          <el-button
            @click="updateBooking()"
            type="primary"
            class="w-100 mg-t-6"
            round
            disabled
            >Add to My Courses</el-button
          >
        </div>
        <!-- status: {{ status }} -->
        <div
          v-if="status == true && getProfiledata != null"
          class="mg-b-1"
          @click="cilckJoin"
        >
          <el-button v-if="status" type="booking" class="w-100 mg-t-6" round
            >Start</el-button
          >
          <!-- </a> -->
        </div>
        <div class="mg-b-1" v-else>
          <el-button
            type="booking"
            class="w-100 mg-t-6"
            round
            :disabled="checkStep"
            >Start</el-button
          >
        </div>
      </div>
      <div v-if="showButton">
        <add-to-calendar
          :title="`DTA - ${badgeEvent.topic}`"
          :location="`Digital Talent Acedemy`"
          :start="
            sessionTimeStamp(
              badgeEvent.sessionDate,
              sessionTime(badgeEvent.sessionStartTime)
            )
          "
          :end="
            sessionTimeStamp(
              badgeEvent.sessionDate,
              sessionTime(badgeEvent.sessionEndTime)
            )
          "
          :details="`DTA ${badgeEvent.courseName} หัวข้อ ${badgeEvent.topic} โดย ${badgeEvent.speakerName} <br> Link: <a herf='${basedUrl}${$route.fullPath}'>${basedUrl}${$route.fullPath}</a>`"
          inline-template
        >
          <div>
            <small>Add to calendar: </small>
            <small>
              <google-calendar id="google-calendar" class="text-primary">
                <i class="fa fa-google"></i> Google
              </google-calendar>
              |
            </small>
            <small>
              <microsoft-calendar id="microsoft-calendar" class="text-primary">
                <i class="fab fa-microsoft"></i> Live
              </microsoft-calendar>
              |
            </small>
            <small>
              <office365-calendar id="office365-calendar" class="text-primary">
                <i class="fa fa-windows"></i> Outlook
              </office365-calendar>
            </small>
          </div>
        </add-to-calendar>
      </div>
    </div>
    <el-dialog
      v-if="confirmDialog"
      :visible.sync="confirmDialog"
      width="98%"
      center
      :destroy-on-close="true"
    >
      <div class="text-center">
        <h3>คุณแน่ใจว่าจะยกเลิก Session นี้?</h3>
        <p>Session: {{ badgeEvent.topic }}</p>
        <p>{{ badgeEvent.speakerName }}</p>
      </div>

      <span slot="footer" class="dialog-footer font-12">
        <div class="is-flex js-between ai-center">
          <a @click="confirmDialog = false" class="cursor-pointer"
            >ไม่ใช่ตอนนี้</a
          >
          <el-button
            type="primary"
            @click="apiUpdateBooking"
            class="font-12 w-auto"
            >ยกเลิก Session</el-button
          >
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import defaultTemplate from "@/template/default.vue";
import Sha1 from "sha1";
import moment from "moment";
import { HTTPBBB } from "@/service/axios";
import { HTTP } from "@/service/axios";
export default {
  name: "createRoom",
  props: ["getBadge"],
  computed: {
    user() {
      return this.$store.state.user;
    },
    EventById() {
      return this.$route.params.id;
    }
  },
  mounted ()
  {
    
    this.getProfile();
  },
  components: {
    defaultTemplate
  },
  data() {
    return {
      confirmDialog: false,
      basedUrl: process.env.VUE_APP_BASE_URL,
      sharedSecret: process.env.VUE_APP_BBB_SHARED_SECRET,
      bbbUrl: process.env.VUE_APP_BBB_BASE_URL,
      regisCourse: 0,
      // 0 คือ เลยวีนที่ปัจจุบัน 1 ลงทะเบียนได้ไม่เกินวัน 2 ลงทะเบียนแล้ว
      status: false,
      // true ลงทะเบียนแล้วคลิกลิงค์  false ไม่ได้ลงทะเบียน
      checkStep: true,
      badgeEvent: null,
      resultJson: null,
      xmlRes: null,
      getProfiledata: null,
      showButton: false
    };
  },
  methods: {
    getAllBadge() {
      HTTP.get( `api/badge/${ this.$route.params.id }` )
        .then( res =>
        {
          console.log( "badge", res );
          if (res.data.success) {
            //this.badgeAll = res.data.obj;
              this.badgeEvent = res.data.obj;
              let badge = res.data.obj;
              let timeEnd = badge.sessionEndTime.split(":");
              let dateEnd = new Date(
                moment(badge.sessionDate).format("YYYY"),
                moment(badge.sessionDate).format("MM") - 1,
                moment(badge.sessionDate).format("DD"),
                Number(timeEnd[0]),
                Number(timeEnd[1]),
                0,
                0
              );
              if (dateEnd < new Date()) {
                this.showButton = false;
              } else {
                this.showButton = true;
              }
            }

            this.registerCourse();
            this.checkStatus();
         
        })
        .catch(e => {
          console.log(e);
        });
    },
    getProfile() {
      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.get(`profile`)
        .then(res => {
          if (res.data.success) {
            this.getProfiledata = res.data.obj;
          }
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        } ).finally( () =>
        {
          this.getAllBadge();
        });
        
    },
    apiUpdateBooking() {
      let booking = {
        badgeId: this.badgeEvent.badgeId,
        status: (this.status = !this.status)
      };
      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.post(`api/book`, booking)
        .then(res => {
          if (res.data.obj.booking) {
            this.$message({
              message: "คุณลงทะเบียนแจ้งเตือนสำเร็จแล้ว",
              type: "success",
              duration: 3000
            });
            this.$router.push("/calendar?book=true");
            this.getAllBadge();
          } else if (!res.data.obj.booking) {
            this.$message({
              message: "คุณได้ถอนการแจ้งเตือนวิชานี้แล้ว",
              type: "warning",
              duration: 3000
            });
            this.confirmDialog = false;
            this.getAllBadge();
          }
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        });
    },
    updateBooking() {
      if (!this.status) {
        this.apiUpdateBooking();
      } else {
        this.confirmDialog = true;
      }
    },
    checkStatus() {
      if (this.badgeEvent != null) {
        let dateNow = new Date().setHours(0, 0, 0, 0);
        // let day = this.badgeEvent.sessionDate.split("/");
        let dateSession = new Date(
          moment(this.badgeEvent.sessionDate).format("YYYY"),
          moment(this.badgeEvent.sessionDate).format("MM") - 1,
          moment(this.badgeEvent.sessionDate).format("DD")
        ).setHours(0, 0, 0, 0);
        this.badgeEvent.book.map(item => {
          // ถ้ามีชื่อในลงทะเบียนแล้ว
          if ( item == this.getProfiledata._id && dateNow <= dateSession) {
            this.regisCourse = 2;
            this.status = true;
          } else if ( item == this.getProfiledata._id ) {
            // this.regisCourse = 1;
            this.status = false;
          }
        });
      }
    },

    registerCourse() {
      //ปุ่มที่จะไม่แสดงกรณีที่เกินเวลาปัจจุบันแล้ว
      console.log("registerCourse");
      let dateNow = new Date().setHours(0, 0, 0, 0);
      if (this.badgeEvent != null) {
        // let day = this.badgeEvent.sessionDate.split("/");
        let dateSession = new Date(
          moment(this.badgeEvent.sessionDate).format("YYYY"),
          moment(this.badgeEvent.sessionDate).format("MM") - 1,
          moment(this.badgeEvent.sessionDate).format("DD")
        ).setHours(0, 0, 0, 0);

        if (dateNow <= dateSession) {
          this.regisCourse = 1;

          console.log("registerCourse 1", this.regisCourse);
        } else {
          this.regisCourse = 0;
          this.status = false;

          console.log("registerCourse 0", this.regisCourse);
        }
      }
    },
    cilckJoin() {
      if (this.badgeEvent != null && this.getProfiledata != null) {
        let dateNow = new Date();
        // let day = this.badgeEvent.sessionDate.split("/");
        let time = this.badgeEvent.sessionStartTime.split(":");
        let startSession = new Date(
          moment(this.badgeEvent.sessionDate).format("YYYY"),
          moment(this.badgeEvent.sessionDate).format("MM"),
          moment(this.badgeEvent.sessionDate).format("DD"),
          time[0],
          time[1],
          0,
          0
        );
        console.log(dateNow, "dateNow");
        console.log(startSession, "startSession");

        let beforeOpen = startSession - dateNow;
        console.log(beforeOpen, "test test");
        // if (beforeOpen < 1800000) {
        console.log("getProfiledata", this.getProfiledata);
        console.log("sharedSecret", this.sharedSecret);
        let name = encodeURIComponent(
          `${this.getProfiledata.firstName} ${this.getProfiledata.lastName}`
        );
        console.log("name", name);

        //----------Start Create Room Triger---------
        let resultHash = Sha1(
          `createallowStartStopRecording=false&attendeePW=ap&autoStartRecording=false&meetingID=${this.badgeEvent.badgeId}&moderatorPW=mp&name=${name}&record=false${this.sharedSecret}`
        );
        let linkCreate = `create?allowStartStopRecording=false&attendeePW=ap&autoStartRecording=false&meetingID=${this.badgeEvent.badgeId}&moderatorPW=mp&name=${name}&record=false&checksum=${resultHash}`;

        HTTPBBB.post(linkCreate)
          .then(res => {
            console.log(res, "Create BBB room!");
          })
          .catch(e => {
            console.log(e);
          });
        //----------End Create Room Triger---------

        let checkSumText = `joinfullName=${name}&meetingID=${this.badgeEvent.badgeId}&password=ap&redirect=true${this.sharedSecret}`;
        let checkSum = Sha1(checkSumText);
        console.log("textBefore SHA-1", checkSumText);
        console.log("checkSum", checkSum);
        console.log("this.user id", this.user);
        let fullLink = `${this.bbbUrl}join?fullName=${name}&meetingID=${this.badgeEvent.badgeId}&password=ap&redirect=true&checksum=${checkSum}&userID=${this.user.id}`;
        console.log("BBB fullLink", fullLink);
        window.open(fullLink + "&openExternalBrowser=1");
        // } else {
        //   this.$message({
        //     message: "ห้องยังไม่เปิด กรุณาเข้าก่อน 30 นาที",
        //     type: "warning",
        //     duration: 3000,
        //   });
        // }
      }
    }
  }
};
</script>
